<!-- src/components/Pagination.vue -->
<template>
    <div class="pagination">
      <button
        :disabled="currentPage === 1"
        @click="changePage(currentPage - 1)"
      >
        Anterior
      </button>
      <span>Página {{ currentPage }} de {{ totalPages }}</span>
      <button
        :disabled="currentPage === totalPages"
        @click="changePage(currentPage + 1)"
      >
        Próxima
      </button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      currentPage: {
        type: Number,
        required: true
      },
      totalItems: {
        type: Number,
        required: true,
        default: 0
      },
      itemsPerPage: {
        type: Number,
        required: true
      }
    },
    computed: {
      totalPages() {
        return Math.ceil(this.totalItems / this.itemsPerPage);
      }
    },
    methods: {
      changePage(pagina) {
        if (pagina > 0 && pagina <= this.totalPages) {
          this.$emit('page-changed', pagina);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  button {
    margin: 0 5px;
  }
  span {
    margin: 0 10px;
  }

/* Estilos para dispositivos móveis */
@media (max-width: 600px) {
  button {
    padding: 15px 30px;
    font-size: 18px;
  }
  span {
    font-size: 18px;
  }
}

/* Estilos para dispositivos com largura entre 601px e 1024px */
@media (min-width: 601px) and (max-width: 1024px) {
  button {
    padding: 12px 24px;
    font-size: 16px;
  }
  span {
    font-size: 16px;
  }
}
  </style>