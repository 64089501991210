<template>
    <div>
      <p>Você está sendo desconectado...</p>
    </div>
</template>
  
  <script>
  import { useRouter } from 'vue-router';
  
  export default {
    setup() {
      const router = useRouter();
  
      // Função para realizar o logout
      const logout = () => {
        // Remove o token do localStorage
        localStorage.removeItem('token');
  
        // Redireciona para a página de login
        router.push('/home');
      };
  
      // Executa o logout assim que o componente é montado
      logout();
  
      return {};
    }
  };
  </script>