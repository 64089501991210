<template>
    <div id="app">    
        <ImageGallery />
    </div>
  </template>
  
  <script>
  import ImageGallery from '../components/GaleriaImagens.vue';
  
  
  export default {
    name: 'App',
    components: {
      ImageGallery
    }  
  };
  </script>
  
