import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/IndexHome.vue';
import About from '../views/AboutMe.vue';
import Fotos from '../views/IndexFotos.vue';
import Logout from '../views/LogoutApi.vue';
import ImageView from '../components/ImagemView.vue';
import IotControl from '@/components/IotControl.vue';

// Define as rotas da aplicação
const routes = [
  { path: '/', redirect: '/home' },
  { path: '/home', component: Home },
  { path: '/about', component: About },
  { path: '/fotos/:page?/:mes?',name: 'Fotos', component: Fotos, meta: { requiresAuth: true },props: true },
  { path: '/logout', component: Logout },
  { path: '/iot', component: IotControl, meta: { requiresAuth: true },props: true},
  { path: '/imagem/:id/:page?/:mes?', name: 'ImageView', component: ImageView, meta: { requiresAuth: true }, props: true }
];

// Cria o roteador com o histórico de navegação baseado em HTML5
const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem('token');
    if (!token) {
      next({ path: '/home' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;