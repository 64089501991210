<template>
    <div>
      <h1>About Page 3</h1>
      <p>Information about us.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutMe'
  };
  </script>