<template>
    <div id="app">    
        <LoginApi />
    </div>
  </template>
  
  <script>
  import LoginApi from '../components/LoginApi.vue';
  
  
  export default {
    name: 'App',
    components: {
      LoginApi
    }  
  };
  </script>
  
