<template>
    <div class="filtros">
           <Filtros :select-mes="filtroMes" @filtro-selecionado="AtualizarFiltros"/>
    </div>
    <div class="gallery">
      <div class="image-container" v-for="image in images" :key="image.id" >
          <router-link :to="{ name: 'ImageView', params: { id: image.id, page: this.currentPage, mes: this.filtroMes} }">
                <img :src="image.url" />
          </router-link>        
      </div>
    </div>
    <Pagination
        :current-page="currentPage"
        :items-per-page="itemsPerPage"
        :total-items="totalItems"
        @page-changed="fetchImages"
     />
  </template>
  
  <script>
  import axios from 'axios';
  import Pagination from './GaleriaPaginacao.vue';
  import Filtros from './GaleriaFiltros.vue';
  import { ref, } from 'vue';
  import { useRoute} from 'vue-router';
  const API_URL=process.env.VUE_APP_API_BASE_URL;
  
  export default {
    components: { Pagination,Filtros },
    data() {
      const route = useRoute();
      return {
        images: [],
        currentPage: ref(parseInt(route.params.page) || 1),
        totalItems: null,
        itemsPerPage: 20,
        filtroMes: route.params.mes || 'Todos'
      };
    },
    watch: {
    page(newPage) {
      this.fetchFotos(newPage);
    }
   },
    created() {
      this.fetchImages(this.currentPage);
    },
    methods: {
      AtualizarFiltros(data){
        this.filtroMes=data.mes;
        this.currentPage=1;
        this.fetchImages(this.currentPage);
      },
      async fetchImages(page) {
        try {
          const token = localStorage.getItem('token'); // Obtém o token do localStorage
          const response = await axios.get(`${API_URL}/api/fotos`, {
            headers: {
              'Authorization': `Bearer ${token}` // Adiciona o token ao cabeçalho
            },
            params:{
              pagina: page,
              limite: this.itemsPerPage,
              mes: this.filtroMes
            }
          });        
          this.images = response.data.dados;
          this.currentPage = page;
          this.totalItems = response.data.info.qtd_linhas;
        } catch (error) {
          console.error('Error fetching images:', error);
        }
      }
    }
  };
  </script>

<style scoped>
@import './css/Galeria.css'; /* Importando o CSS específico do componente */
</style>