<template>
  <div class="image-view">
     <router-link :to="{ name: 'Fotos', params: { page: this.page, mes: this.mes} }" class="btn">↪ Voltar </router-link>
     <div>
        <button @click="showModal = true">Open Modal</button>
        <Modal :isVisible="showModal" @close="showModal = false"></Modal>
     </div>
     <div v-if="image">
       <h1>{{ image.title }}</h1>
       <img :src="image.url" alt="Image description" />
       <div class="caption-box">
         <h3>Legenda</h3>
         <p class="caption-date">Data: {{ image.dt_criacao }}</p>
         <p class="caption-location">Local: {{ image.no_cidade }} / {{ image.no_estado }} / {{ image.cidade }}</p>
         <p class="caption-tags">Tags: #praia #verão #turismo</p>
       </div>
     </div>
     <div v-else>
       <p>Carregando...</p>
     </div>
   </div>
</template>

<script>
import axios from 'axios';
import Modal from './LocalidadeModal.vue';
axios.defaults.withCredentials = true;
const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    Modal
  },
 data() {
   return {
     image: null,
     page: 4,
     mes: '',
     showModal: false,
     chave:null
   };
 },
 created() {
   this.fetchImage();
 },
 methods: {
   async fetchImage() {
     const id = this.$route.params.id;
     this.page = this.$route.params.page;
     this.mes = this.$route.params.mes;
     console.log("teste")
     try {
       const token = localStorage.getItem('token'); // Obtém o token do localStorage
       this.chave=token
       const response = await axios.get(`${API_URL}/api/foto/${id}`, {
         headers: {
           'Authorization': `Bearer ${token}` // Adiciona o token ao cabeçalho
         }
       }); // URL da sua API para obter os detalhes da imagem
       this.image = response.data[0];
       console.log(this.image)
     } catch (error) {
       console.error('Erro ao obter detalhes da imagem:', error);
     }
   }
 }
};
</script>

<style>
body {
 font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.image-view {
 align-items: center;
 text-align: center;
}

.image-view img {
 max-width: 1200px;
 height: auto;
 border-radius: 10px;
 box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn {
 background-color: rgba(0, 0, 0, 0.7);
 color: white;
 padding: 10px 20px;
 border: none;
 border-radius: 4px;
 cursor: pointer;
 text-align: center;
 text-decoration: none;
}

.caption-box {
 max-width: 1200px;
 background-color: rgba(0, 0, 0, 0.7);
 color: #ffffff;
 padding: 10px;
 border-radius: 5px;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
 margin: 10px auto;
}

.caption-box h3 {
 margin: 5px 0;
 font-size: 16px;
}

.caption-box p {
 margin: 5px 0;
 font-size: 14px;
}

@media (min-width: 601px) and (max-width: 1024px) {
 .image-view img {
   max-width: 100%;
   height: auto;
 }
}

@media (max-width: 600px) {
 .image-view img {
   max-width: 100%;
   height: auto;
 }
}
</style>