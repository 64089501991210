<template>
    <div class="filter-container">
      <select @change="onSelectChange" v-model="mes" class="month-select">
        <option v-for="date in dates" :key="date" :value="date.mes">
          {{ date.mes }}
        </option>
      </select>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  const API_URL=process.env.VUE_APP_API_BASE_URL;
  console.log("Aqui: "+API_URL)
  export default {
    props: {
    selectMes: {
      type: String,
      required: true,
      default: ''
    }
    },
    data() {
      return {
        dates: [], // Lista de Ano/Mês
        mes: ''
      };
    },
    mounted() {
      // Carregar a lista de Ano/Mês do JSON ou definir diretamente
      this.preencherMes();
      if (this.selectMes != 'Todos') this.mes=this.selectMes;  
    },
    methods: {
      onSelectChange() {
        console.log("Filtro2:"+this.selectMes);  
        this.$emit('filtro-selecionado', { mes: this.mes })  
      },
      async preencherMes() {
        try {
          const token = localStorage.getItem('token'); // Obtém o token do localStorage
          const response = await axios.get(`${API_URL}/api/filtros/mes`, {
           headers: {
              'Authorization': `Bearer ${token}` // Adiciona o token ao cabeçalho
            },
            params:{         }
          }); 
          this.dates=response.data;
        } catch (error) {
          console.error('Error fetching images:', error);
        }
        }
   }
  };
  </script>

<style>

.filter-container {
  margin: 0 auto;
  max-width: 100%;
  height: 100px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-container label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.month-select {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
}

.month-select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 3px rgba(38, 143, 255, 0.5);
}

.month-select option {
  padding: 10px;
}

</style>