<template>
    <div>
      <h1>Device Control Grid</h1>
      <div>
        <button @click="alteraAutomatico()">
                  AutoOff - Luz
        </button>
      </div>
      <div v-if="loading">Loading devices...</div>
      <div v-else>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Status</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="device in devices" :key="device.id">
              <td>{{ device.nome }}</td>
              <td>
                <span :class="{'status-on': device.status === 1, 'status-off': device.status === 0}">
                  {{ device.status }}
                </span>
              </td>
              <td>
                <button @click="toggleDeviceStatus(device)">
                  {{ device.status === 1 ? 'Off' : 'On' }}
                </button>
              </td>
           </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        devices: [],
        loading: true,
      };
    },
    created() {
      this.fetchDevices();
      this.startAutoRefresh();
    },
    beforeUnmount() {
      // Limpar o intervalo quando o componente for destruído
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
    methods: {
      async fetchDevices() {
        try {
          const response = await fetch('https://api.siriusmain.com/list');
          this.devices = await response.json();
        } catch (error) {
          console.error('Error fetching devices:', error);
        } finally {
          this.loading = false;
        }
      },
      startAutoRefresh() {
      // Atualiza os dados a cada 5 segundos
         this.intervalId = setInterval(this.fetchDevices, 5000);
      },
      async toggleDeviceStatus(device) {
        try {
          const newStatus = device.status === 1 ? 'off' : 'on';
          // Assuming there's an API endpoint to update device status
          const url = `https://api.siriusmain.com/devices?id=${device.nome}&status=${newStatus}`;        
          await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }            
          });
          device.status = newStatus;
        } catch (error) {
          console.error('Error updating device status:', error);
        }
        this.fetchDevices();
        this.startAutoRefresh();
      },
      async alteraAutomatico() {
        try {
          // Assuming there's an API endpoint to update device status
          const url = `https://api.siriusmain.com/autoOff`;        
          await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }            
          });
        } catch (error) {
          console.error('Error updating device status:', error);
        }
        this.fetchDevices();
        this.startAutoRefresh();
      },
    },
  };
  </script>
  
  <style scoped>
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .status-on {
    color: green;
    font-weight: bold;
  }
  
  .status-off {
    color: red;
    font-weight: bold;
  }
  
  button {
    padding: 5px 10px;
    cursor: pointer;
  }
  </style>